<template>
  <div class="slider-component">
    <button
      v-show="isPrevVisible"
      :disabled="!hasPrevItem"
      :class="arrowClass"
      class="slider-prev"
      aria-label="Previous group items"
      @click="scrollToPrevItems"
    >
      <slot name="prev-button">
        <div class="slider-prev-icon">
          <BaseSpriteIcon icon-name="ico-arrow-right" view-box="0 0 32 32" />
        </div>
      </slot>
    </button>
    <div
      ref="sliderElement"
      class="slider-wrapper"
      v-on="sliderEvents"
      @click.capture="preventIfNeed"
    >
      <div class="slider" :style="sliderStyles">
        <div
          v-for="(item, index) in paginateList"
          :key="`${item[itemKey]}_${index}`"
          ref="items"
          v-life-cycle="{
            mounted: el => itemMounted(el),
            destroyed: el => itemDestroyed(el)
          }"
          :data-book-index="index"
          class="slider-item"
        >
          <slot
            v-if="index >= renderRange[0] && index <= renderRange[1]"
            name="item"
            :item="item"
            :index="index"
          ></slot>
        </div>
      </div>
    </div>
    <button
      v-show="isNextVisible"
      :disabled="!isNextVisible"
      :class="arrowClass"
      class="slider-next"
      aria-label="Next group items"
      @click="scrollToNextItems"
    >
      <slot name="next-button">
        <div class="slider-next-icon">
          <BaseSpriteIcon icon-name="ico-arrow-right" view-box="0 0 32 32" />
        </div>
      </slot>
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import debounce from 'lodash/debounce';

import Utils from '@/services/utils/Utils';
import LayoutManager from '@/services/utils/LayoutManager';

import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';

export default {
  name: 'SlideGroup',
  components: { BaseSpriteIcon },
  directives: {
    'life-cycle': {
      bind(el, { value }) {
        value.mounted(el);
      },
      unbind(el, { value }) {
        value.destroyed(el);
      }
    }
  },
  props: {
    list: Array,
    lazyLoadItems: Number,
    itemKey: {
      type: String,
      default: 'id'
    },
    alwaysVisible: {
      type: Boolean,
      default: false
    },
    showArrows: {
      type: Boolean,
      default: true
    },
    alignItem: {
      type: Boolean,
      default: false
    },
    arrowClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      pressed: false,
      touched: false,
      oldMove: 0,
      isMounted: false,
      scrollTimeout: null,
      needPreventClick: 0,
      sliderStyles: {},
      itemsVisibility: {},
      sliderEvents: {
        mousedown: this.mouseDownEvent.bind(this),
        mousemove: this.mouseMove.bind(this),
        mouseup: this.mouseLeaveAndUp.bind(this),
        mouseleave: this.mouseLeaveAndUp.bind(this),
        touchstart: this.touchStart.bind(this),
        touchend: this.touchEnd.bind(this),
        scroll: this.stickElementIfNeed.bind(this)
      }
    };
  },
  computed: {
    isPrevVisible() {
      return this.alwaysVisible || (this.showArrows && this.hasPrevItem);
    },
    isNextVisible() {
      return this.alwaysVisible || (this.showArrows && this.hasNextItem);
    },
    loadItems() {
      return this.lazyLoadItems || this.list.length;
    },
    paginateList() {
      return this.list.slice(0, this.renderRange[1] + 1);
    },
    renderRange() {
      return [
        Math.max(this.visibilityRang[0] - this.loadItems, 0),
        this.visibilityRang[1] + this.loadItems
      ];
    },
    visibilityRang() {
      const visibleIndexes = Object.entries(this.itemsVisibility).reduce(
        (result, [key, val]) => {
          if (val) {
            result.push(parseInt(key));
          }
          return result;
        },
        []
      );
      let start = 0;
      let end = this.loadItems - 1;
      if (visibleIndexes.length && this.list) {
        start = Math.max(Math.min(...visibleIndexes));
        end = Math.max(...visibleIndexes);
      }
      return [start, end];
    },
    ...mapGetters('ContextStore', ['getSystemLanguage']),
    isRtl() {
      return Utils.getDirection(this.getSystemLanguage) === 'rtl';
    },
    hasPrevItem() {
      return this.visibilityRang[0] !== 0;
    },
    hasNextItem() {
      return this.visibilityRang[1] !== this.list.length - 1;
    },
    elementToScrollIntoView() {
      const elementIndex = this.hasNextItem
        ? this.visibilityRang[0]
        : this.visibilityRang[1];
      return this.$refs.items[elementIndex];
    }
  },
  watch: {
    getSystemLanguage() {
      this.setWidthForRtl();
    },
    list() {
      this.setWidthForRtl();
    }
  },
  mounted() {
    this.setWidthForRtl();
    const options = {
      root: this.$el,
      threshold: 0.5
    };
    this.itemsObserver = new IntersectionObserver(entries => {
      const newVisibilityStatuses = {};
      entries.forEach(entry => {
        newVisibilityStatuses[entry.target.dataset.bookIndex] =
          entry.isIntersecting;
      });
      this.itemsVisibility = {
        ...this.itemsVisibility,
        ...newVisibilityStatuses
      };
    }, options);
    this.$refs.items.forEach(component =>
      this.itemsObserver.observe(component)
    );
    this.isMounted = true;

    LayoutManager.register({
      layout: debounce(this.onLayoutChange.bind(this), 200),
      id: this._uid
    });
  },
  destroyed() {
    LayoutManager.unregister(this._uid);
  },
  methods: {
    itemMounted(el) {
      if (this.isMounted && el) {
        this.itemsObserver.observe(el);
      }
    },
    itemDestroyed(el) {
      if (this.isMounted && el) {
        this.itemsObserver.unobserve(el);
      }
    },
    preventIfNeed(ev) {
      if (this.needPreventClick) {
        ev.preventDefault();
      }
    },
    mouseDownEvent(ev) {
      this.needPreventClick = false;
      ev.preventDefault();
      this.pressed = true;
    },
    mouseMove(ev) {
      if (!this.pressed) {
        return;
      }
      this.oldMove = this.oldMove || ev.x;
      this.$refs.sliderElement.scrollTo(
        this.$refs.sliderElement.scrollLeft + (this.oldMove - ev.x),
        0
      );
      this.oldMove = ev.x;
      this.needPreventClick = true;
    },
    mouseLeaveAndUp() {
      const prevPressedState = this.pressed;
      this.pressed = false;
      this.oldMove = 0;
      if (!prevPressedState || !this.alignItem) {
        return;
      }
      this.scrollElementIntoView(this.elementToScrollIntoView);
    },
    touchStart() {
      this.touched = true;
    },
    touchEnd() {
      if (this.alignItem && this.touched) {
        this.scrollElementIntoViewWithTimeout();
      }
      this.touched = false;
    },
    scrollToPrevItems() {
      if (this.hasPrevItem) {
        const prevSet =
          this.visibilityRang[0] -
          (this.visibilityRang[1] - this.visibilityRang[0]) -
          1;
        this.scrollElementIntoView(this.$refs.items[Math.max(prevSet, 0)]);
      }
    },
    scrollToNextItems() {
      if (this.hasNextItem) {
        const nextSet = this.visibilityRang[1] + 1;
        this.scrollElementIntoView(
          this.$refs.items[Math.min(nextSet, this.list.length - 1)]
        );
      }
    },
    scrollElementIntoViewWithTimeout(ms = 100) {
      clearTimeout(this.scrollTimeout);
      this.scrollTimeout = setTimeout(() => {
        this.scrollElementIntoView(this.elementToScrollIntoView);
      }, ms);
    },
    stickElementIfNeed() {
      if (!this.alignItem || this.pressed || this.touched) {
        return;
      }
      this.scrollElementIntoViewWithTimeout(300);
    },
    scrollElementIntoView(el) {
      let offsetLeft;
      if (this.isRtl) {
        offsetLeft =
          (this.$refs.sliderElement.clientWidth -
            el.offsetWidth -
            el.offsetLeft) *
          -1;
      } else {
        offsetLeft = el.offsetLeft;
      }
      this.$refs.sliderElement.scrollTo({
        left: offsetLeft,
        top: 0,
        behavior: 'smooth'
      });
    },
    onLayoutChange({ events }) {
      if (!events.resizing || !this.$refs.items.length) {
        return;
      }
      this.setWidthForRtl();
      this.scrollElementIntoView(this.$refs.items[this.visibilityRang[0]]);
    },
    setWidthForRtl() {
      if (!this.isRtl) {
        this.sliderStyles = {};
        return;
      }
      const el = this.$refs.items[0];
      this.sliderStyles = {
        width: el.offsetWidth * this.list.length + 'px'
      };
    }
  }
};
</script>

<style lang="less" src="./SlideGroup.less"></style>
